import { CreateUserCredential, SocialLoginCredential } from './../models/index.js';
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { log } from '@awread/kernel/tools';
@Injectable({ providedIn: 'root' })
export class AuthApi {
  constructor(private apollo: Apollo, private http: HttpClient) {}

  changePassword(form: any) {
    return this.apollo
      .mutate<any>({
        mutation: gql`
          mutation changePassword($oldpass: String!, $newpass: String!) {
            changePassword(input: { oldpass: $oldpass, newpass: $newpass }) {
              results {
                success
                message
                data
              }
            }
          }
        `,
        variables: {
          oldpass: form.oldpassword,
          newpass: form.password,
        },
      })
      .pipe(
        map((res) => res.data?.['changePassword']['results'][0]),
        log()
      );
  }

  checkNid(nid: string) {
    return this.apollo
      .query<any>({
        query: gql`
          query checkNidAuth($nid: String!) {
            users(condition: { userNid: $nid }) {
              totalCount
            }
          }
        `,
        variables: { nid },
      })
      .pipe(map((result: any) => result?.['data']?.['users']?.['totalCount']));
  }

  authenticateUser(variables: CreateUserCredential) {
    return this.apollo
      .mutate<any>({
        mutation: gql`
          mutation authenticateUser($loginname: String, $password: String) {
            authenticateUser(input: { loginname: $loginname, password: $password }) {
              results {
                accessToken
                matchPassword
                provider
                emailVerified
                user {
                  name
                  userId
                }
              }
            }
          }
        `,
        variables,
      })
      .pipe(map((res) => res.data?.['authenticateUser']?.['results'][0]));
  }

  authenticateSocialToServer(variables: { provider: any; authToken: any }) {
    return this.http.post('/api/auth/verify', variables).pipe(
      map((result: any) => {
        if (result.accessToken) {
          return {
            case: 'success',
            ...result,
          };
        } else {
          return {
            case: 'account-not-exist',
            caseName: 'account-not-exist',
            ...result,
          };
        }
      })
    );
  }

  checkAccount(checkFields: any) {
    return this.http.post('/api/auth/check-account', checkFields).pipe(
      map((result: any) => {
        if (result.check_account) {
          return result.check_account;
        } else {
          return {};
        }
      })
    );
  }

  registerUser(variables: CreateUserCredential & { socialConnect: any; provider: string }) {
    return this.apollo
      .mutate<any>({
        mutation: gql`
          mutation registerUser($name: String, $username: String, $password: String, $email: String, $phone: String, $provider: String, $socialConnect: JSON) {
            registerUser(
              input: { name: $name, username: $username, password: $password, email: $email, phone: $phone, provider: $provider, socialConnect: $socialConnect }
            ) {
              results {
                case
                accessToken
                user {
                  userId
                  email
                  phone
                  username
                  name
                  userNid
                }
                duplicateField
              }
            }
          }
        `,
        variables: {
          ...variables,
        },
      })
      .pipe(
        map((res) => res.data?.['registerUser']?.['results']?.[0])
        // map((result: any) => {

        //   }
        // })
      );
  }
}
